import React from "react"
import { getItem } from "../../util/functions"
import ConfirmationComponent from "./ConfirmationComponent"

const FreeConsultationFormConfirmation = () => {
  return (
    <ConfirmationComponent
      firstName={getItem("firstName")}
      lastName={getItem("lastName")}
      practice={getItem("practice")}
      ctaUrl={process.env.BASE}
      page="consultation"
    />
  )
}

export default FreeConsultationFormConfirmation
