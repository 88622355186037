import React from "react"
import FreeConsultationFormConfirmation from "../components/Form/FreeConsultationFormConfirmation"
import Layout from "../helper/layout"

const FreeConsultationFormConfirmationPage = () => {
  return (
    <Layout>
      <FreeConsultationFormConfirmation />
    </Layout>
  )
}

export default FreeConsultationFormConfirmationPage
